export default [
  {
    path: "/",
    name: "home",
    meta: {
      hideInMenu: true,
      title: "加诺虚拟海外仓 USPS折扣面单｜海外仓换单分发｜退换货｜英文客服",
      notCache: true,
    },
    redirect: '/home'
  }, 
  {
    path: "/home",
    name: "home",
    meta: {
      hideInMenu: true,
      title: "加诺虚拟海外仓 USPS折扣面单｜海外仓换单分发｜退换货｜英文客服",
      notCache: true,
    },
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      hideInMenu: true,
      title: "加诺虚拟海外仓 USPS折扣面单｜海外仓换单分发｜退换货｜英文客服",
      notCache: true,
    },
    component: () => import("@/views/about"),
  }
];

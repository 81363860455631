import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.use(VueRouter);
Vue.use(ViewUI);

// The routing configuration

const defaultTitle = '加诺虚拟海外仓 USPS折扣面单｜海外仓换单分发｜退换货｜英文客服';

router.beforeEach((to, from, next) => {
 document.title = to.meta.title ? to.meta.title : defaultTitle
 next()
})

new Vue({
    el: '#app',
    router: router,
    render: h => h(App)
});
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    this.calcuFontsize()
  },
  methods:{
    calcuFontsize(){
      let htmlWidth=document.documentElement.cilentWidth||document.body.clientWidth;
      let html=document.documentElement;//获取htm元素
      if(htmlWidth>1000){
        html.style.fontSize=(htmlWidth/1920)*100+'px';//设置html的根元素大小
      }else{
        html.style.fontSize = '55px'
      }
    } 
  }
};
</script>
<style lang="less">

</style>
